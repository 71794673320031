import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import { HashRouter, Route, Routes } from "react-router-dom";
import "./index.css";
import "./util/fetchInterceptor";
import Cookies from "js-cookie";
import { Navigate } from "react-router-dom";
import { useMenuContext } from "./MenuContext";
import { RagasProvider } from "./RagasProvider";
import { SoothGptProvider } from "./SoothGptProvider";

const Layout = React.lazy(() => import("./pages/layout/Layout"));
const NoPage = React.lazy(() => import("./pages/NoPage"));
const Logout = React.lazy(() => import("./pages/logout"));
const OneShot = React.lazy(() => import("./legacy/oneshot/OneShot"));
const Chat = React.lazy(() => import("./legacy/chat/Chat"));
const PurePlus = React.lazy(() => import("./pages/pureplus/PurePlus"));
const Dalle3 = React.lazy(() => import("./pages/dalle3/Dalle3"));
const Login = React.lazy(() => import("./pages/login/Login"));
const TalkPage = React.lazy(() => import("./pages/talk/Talk"));
const Bing = React.lazy(() => import("./pages/Bing/Bing"));
const DalleLayout = React.lazy(() => import("./pages/DalleLayout/DalleLayout"));
const BrazilTaxCode = React.lazy(() => import("./legacy/BrazilTaxCode/BrazilTaxCode"));
const View = React.lazy(() => import("./pages/Ragas/View"));
const Evaluation = React.lazy(() => import("./pages/Ragas/Evaluation"));
const RagasSummary = React.lazy(() => import("./pages/Ragas/RagasSummary"));
const EvaluationFeedback = React.lazy(() => import("./pages/Ragas/EvaluationFeedback"));
const RagasLayout = React.lazy(() => import("./pages/Ragas/RagasLayout"));
const PurePlusO = React.lazy(() => import("./pages/purepluso/PurePlusO"));
const SoothsayerConcierge = React.lazy(() => import("./pages/soothsayerConcierge/SoothsayerConcierge"));
const Usecase = React.lazy(() => import("./pages/usecase/Usecase"));
const Loading = React.lazy(() => import("./components/Loading/Loading"));
const Unauthorized = React.lazy(() => import("./pages/Unauthorized/index"));

export default function App() {
    const isUserLoggedIn = Cookies.get("isUserLoggedIn");
    const { isMenuLoading } = useMenuContext() || {};

    return (
        <HashRouter>
            <SoothGptProvider>
                <RagasProvider>
                    <Suspense fallback={<Loading />}>
                        {isMenuLoading ? (
                            <Loading />
                        ) : (
                            <Routes>
                                <Route
                                    index
                                    element={
                                        <Suspense fallback={<Loading />}>
                                            {isMenuLoading ? <Loading /> : <Navigate to={`${isUserLoggedIn == "True" ? "/purepluso" : "/login"}`} replace />}
                                        </Suspense>
                                    }
                                />
                                <Route path="/" element={<Suspense fallback={<Loading />}>{isMenuLoading ? <Loading /> : <Layout />}</Suspense>}>
                                    <Route path="/" element={<Navigate to="/purepluso" replace />} />
                                    <Route path="/soothsayer-concierge/:chat_session_id" element={<SoothsayerConcierge />} />
                                    <Route path="/soothsayer-concierge" element={<SoothsayerConcierge />} />
                                    <Route path="/chat/:chat_session_id" element={<Chat />} />
                                    {/* <Route path="/:chat_session_id" element={<Chat />} /> */}
                                    <Route path="/bing/:chat_session_id" element={<Bing />} />
                                    <Route path="/bing" element={<Bing />} />
                                    <Route path="/pure/:chat_session_id" element={<Navigate to="/purepluso" />} />
                                    <Route path="/pure" element={<Navigate to="/purepluso" />} />
                                    <Route path="/usecase/:name/:chat_session_id" element={<Usecase />} />
                                    <Route path="/usecase/:name" element={<Usecase />} />
                                    <Route path="qa" element={<OneShot />} />
                                    <Route path="/ask/:chat_session_id" element={<OneShot />} />
                                    <Route path="/ask" element={<OneShot />} />
                                    <Route path="/qa/:chat_session_id" element={<OneShot />} />
                                    <Route path="/pureplus/:chat_session_id" element={<PurePlus />} />
                                    <Route path="/pureplus" element={<PurePlus />} />
                                    <Route path="/purepluso/:chat_session_id" element={<PurePlusO />} />
                                    <Route path="/purepluso" element={<PurePlusO />} />
                                    <Route path="/talk/:chat_session_id" element={<TalkPage />} />
                                    <Route path="/talk" element={<TalkPage />} />
                                    {/* <Route path="/legalteams" element={<LegalTeams />} />
                                <Route path="/legalteam/approvedchats" element={<LegalTeamsApproved />} />
                                <Route path="/business_user" element={<Chater />} />
                                <Route path="/business_user/:chat_session_id" element={<Chater />} /> */}
                                </Route>
                                <Route path="/" element={<Suspense fallback={<Loading />}>{isMenuLoading ? <Loading /> : <DalleLayout />}</Suspense>}>
                                    <Route path="/braziltaxcode" element={<BrazilTaxCode />} />
                                    <Route path="/dalle" element={<Dalle3 />} />
                                    <Route path="/dalle3" element={<Dalle3 />} />
                                    <Route element={<RagasLayout />}>
                                        <Route path="/ground_truth" element={<View />} />
                                        <Route path="/evaluation" element={<View />} />
                                        <Route path="/result" element={<RagasSummary />} />
                                        <Route path="/result/:ragId" element={<Evaluation />} />
                                        <Route path="/evaluation_feedback" element={<RagasSummary />} />
                                        <Route path="/evaluation_feedback/:ragId" element={<EvaluationFeedback />} />
                                    </Route>
                                </Route>
                                <Route path="/login" element={<Login />} />
                                <Route path="/logout" element={<Logout />} />
                                <Route path="/unauthorized" element={<Unauthorized />} />
                                <Route path="/404" element={<NoPage />} />
                                <Route path="*" element={<Unauthorized />} />
                            </Routes>
                        )}
                    </Suspense>
                </RagasProvider>
            </SoothGptProvider>
        </HashRouter>
    );
}

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(<App />);
