import Cookies from "js-cookie";
import { isExternalLink, makeValidUrl } from "../util";
import type {
    AskRequest,
    AskResponse,
    BrazilJurisdictionsRequest,
    ChatRequest,
    ChatThread,
    ChatThreadResponse,
    ImageRequest,
    LegalChat,
    LegalChatReponse,
    MenuItemResponse,
    ReactToMessageRequest,
    UsecaseParamsType,
    userChatsResponse
} from "./models";

export async function askApi(options: AskRequest): Promise<AskResponse> {
    const response = await fetch("/ask", {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: stringifyReqBody(options)
    });

    const parsedResponse: AskResponse = await response.json();
    if (response.status === 403) {
        window.location.replace(`${location.origin}/#/login`);
    }
    if (response.status > 299 || !response.ok) {
        throw Error(parsedResponse.error || "Unknown error");
    }

    return parsedResponse;
}

export async function chatApi(options: ChatRequest, pure_plus_o_mode = false, pure_plus_mode = false, apiEndPoint = ""): Promise<AskResponse> {
    const input_url = pure_plus_mode ? "pureplus" : pure_plus_o_mode ? "/purepluso" : apiEndPoint ? apiEndPoint : "/chat";

    const response = await fetch(input_url, {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: stringifyReqBody(options)
    });

    const parsedResponse: AskResponse = await response.json();
    if (response.status === 403) {
        const isUserLoggedIn: boolean = Cookies.get("isUserLoggedIn") == "False" || Cookies.get("isUserLoggedIn") == undefined;
        if (isUserLoggedIn) {
            window.location.replace(`${location.origin}/#/login`);
        } else {
            window.location.replace(`${location.origin}/login`);
        }
    }
    if (response.status > 299 || !response.ok) {
        throw Error(parsedResponse.error || "Unknown error");
    }

    return parsedResponse;
}

export async function bingChatApi(options: ChatRequest, pure_mode = false, pure_plus_mode = false): Promise<AskResponse> {
    const input_url = "/bing";

    const response = await fetch(input_url, {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: stringifyReqBody(options)
    });

    const parsedResponse: AskResponse = await response.json();
    if (response.status === 403) {
        window.location.replace(`${location.origin}/#/login`);
    }
    if (response.status > 299 || !response.ok) {
        throw Error(parsedResponse.error || "Unknown error");
    }

    return parsedResponse;
}

export async function audioApi(formData: any): Promise<any> {
    const input_url = "/audio";

    const response = await fetch(input_url, {
        method: "POST",
        body: formData
    });

    const parsedResponse: any = await response.json();
    if (response.status === 403) {
        window.location.replace(`${location.origin}/#/login`);
    }
    if (response.status > 299 || !response.ok) {
        throw Error(parsedResponse.error || "Unknown error");
    }

    return parsedResponse;
}
export async function talkChatApi(options: ChatRequest): Promise<AskResponse> {
    const input_url = "/talk/" + options.session_id;

    const response = await fetch(input_url, {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: stringifyReqBody(options)
    });

    const parsedResponse: AskResponse = await response.json();
    if (response.status === 403) {
        window.location.replace(`${location.origin}/#/login`);
    }
    if (response.status > 299 || !response.ok) {
        throw Error(parsedResponse.error || "Unknown error");
    }

    return parsedResponse;
}

export async function usecaseApi(options: ChatRequest, name: string): Promise<AskResponse> {
    const input_url = "/usecase/" + name;

    const response = await fetch(input_url, {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: stringifyReqBody(options)
    });

    const parsedResponse: AskResponse = await response.json();
    if (response.status === 403) {
        window.location.replace(`${location.origin}/#/login`);
    }
    if (response.status > 299 || !response.ok) {
        throw Error(parsedResponse.error || "Unknown error");
    }

    return parsedResponse;
}
export async function usecaseParamApi(name: string): Promise<UsecaseParamsType> {
    const input_url = "/params/" + name;

    const response = await fetch(input_url, {
        method: "GET",
        headers: {
            "Content-Type": "application/json"
        }
    });

    const parsedResponse: UsecaseParamsType = await response.json();
    if (response.status === 403) {
        window.location.replace(`${location.origin}/#/login`);
    }
    if (response.status > 299 || !response.ok) {
        throw Error(parsedResponse.error || "Unknown error");
    }

    return parsedResponse;
}
export async function imageApi(options: ImageRequest, version = "dalle"): Promise<AskResponse> {
    const input_url = version === "dalle3" ? "/dalle3" : "dalle";
    const response = await fetch(input_url, {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            prompt: options.prompt,
            approach: options.approach,
            session_id: options.session_id,
            isVariant: options.isVariant,
            overrides: {
                semantic_ranker: options.overrides?.semanticRanker,
                semantic_captions: options.overrides?.semanticCaptions,
                top: options.overrides?.top,
                temperature: options.overrides?.temperature,
                prompt_template: options.overrides?.promptTemplate,
                prompt_template_prefix: options.overrides?.promptTemplatePrefix,
                prompt_template_suffix: options.overrides?.promptTemplateSuffix,
                exclude_category: options.overrides?.excludeCategory,
                suggest_followup_questions: options.overrides?.suggestFollowupQuestions
            }
        })
    });

    const parsedResponse: AskResponse = await response.json();
    if (response.status === 403) {
        window.location.replace(`${location.origin}/#/login`);
    }
    if (response.status > 299 || !response.ok) {
        throw Error(parsedResponse.error || "Unknown error");
    }

    return parsedResponse;
}

export async function UserMenuApi(): Promise<MenuItemResponse> {
    const input_url = "/menu_items";
    try {
        const response = await fetch(input_url, {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        });

        const parsedResponse: MenuItemResponse = await response.json();
        if (response.status === 403) {
            Cookies.set("isUserLoggedIn", "False");
            window.location.replace(`${location.origin}/#/login`);
        } else {
            Cookies.set("isUserLoggedIn", "True");
        }
        if (response.status > 299 || !response.ok) {
            throw Error(parsedResponse.error || "Unknown error");
        }
        return parsedResponse;
    } catch (error) {
        console.error({ error });
        return { menus: [], threads: [], error: "error" };
    }
}

export async function UserThreadsApi(): Promise<ChatThreadResponse> {
    const input_url = "/get_threads";
    try {
        const response = await fetch(input_url, {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        });

        const parsedResponse: ChatThreadResponse = await response.json();
        if (response.status === 403) {
            window.location.replace(`${location.origin}/#/login`);
        }
        if (response.status > 299 || !response.ok) {
            throw Error(parsedResponse.error || "Unknown error");
        }
        return parsedResponse;
    } catch (error) {
        console.error({ error });
        return { Threads: [], error: "error" };
    }
}

export async function UserThreadChatsApi(chat_session_id: string, chat_mode: string): Promise<userChatsResponse> {
    const input_url = "/get_thread_chats/" + chat_session_id;
    const data: Record<string, string> = {
        mode: chat_mode
    };

    const queryString = Object.keys(data)
        .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
        .join("&");

    try {
        const response = await fetch(input_url, {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        });

        const parsedResponse: userChatsResponse = await response.json();
        if (response.status === 403) {
            window.location.replace(`${location.origin}/#/login`);
        }
        if (response.status > 299 || !response.ok) {
            throw Error(parsedResponse.error || "Unknown error");
        }
        return parsedResponse;
    } catch (error) {
        console.error({ error });
        return { thread_chats: [], error: "error" };
    }
}

export async function LegalChatSubmitsApi(data: LegalChat): Promise<any> {
    const input_url = "/legal_chats";

    try {
        const response = await fetch(input_url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        });

        const parsedResponse: any = await response.json();
        if (response.status === 403) {
            window.location.replace(`${location.origin}/#/login`);
        }
        if (response.status > 299 || !response.ok) {
            throw Error(parsedResponse.error || "Unknown error");
        }
        return parsedResponse;
    } catch (error) {
        console.error({ error });
        return { chats: [], error: "error" };
    }
}

export async function LegalChatsApi(isApproved = false): Promise<LegalChatReponse> {
    const input_url = isApproved ? "/legal_approved_chats/all" : "/legal_chats/all";

    try {
        const response = await fetch(input_url, {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        });

        const parsedResponse: LegalChatReponse = await response.json();
        if (response.status === 403) {
            window.location.replace(`${location.origin}/#/login`);
        }
        if (response.status > 299 || !response.ok) {
            throw Error(parsedResponse.error || "Unknown error");
        }
        return parsedResponse;
    } catch (error) {
        console.error({ error });
        return { chats: [], error: "error" };
    }
}

export async function BrazilJurisdictionsApi(data: BrazilJurisdictionsRequest): Promise<any> {
    const input_url = "/brazil_tax_codes";

    try {
        const response = await fetch(input_url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        });

        const parsedResponse: any = await response.json();
        if (response.status === 403) {
            window.location.replace(`${location.origin}/#/login`);
        }
        if (response.status > 299 || !response.ok) {
            throw Error(parsedResponse.error || "Unknown error");
        }
        return parsedResponse;
    } catch (error) {
        console.error({ error });
        return { rows: [], error: "error" };
    }
}

/**
 * Returns the file path for a given citation. The citation can be in various formats:
 * - If it starts with "con_", it is split by "_" and the "con_" part is removed.
 * - If it starts with "source:", the "source: " part is removed.
 * - If it starts with "http", it is returned as is.
 * - If a `talkThread` is provided, the citation is appended to "/talk/content/{talkThread}".
 * - If a `usecaseName` is provided, the citation is appended to "/uc/content/{usecaseName}".
 * - Otherwise, the citation is appended to "/content/".
 *
 * @param {string} citation - The citation for which to get the file path.
 * @param {string} [usecaseName] - The name of the use case, if applicable.
 * @param {string} [talkThread] - The name of the talk thread, if applicable.
 * @returns {string} - The file path for the citation.
 *
 * @function getCitationFilePath
 * @export
 */
export function getCitationFilePath(citation: string, usecaseName?: string, talkThread?: string): string {
    const isWebLink = isExternalLink(citation || "") || false;
    if (citation.startsWith("con_")) {
        const ext = citation.split("_");
        citation = citation.replace("con_" + ext[1] + "_", "").replace(".txt", "." + ext[1]);
    }
    if (citation.startsWith("source:")) {
        const ext = citation.split(" ");
        citation = citation.replace("source: ", "");
    }
    if (isWebLink) {
        return isWebLink ? makeValidUrl(citation || "") : citation;
    }
    if (talkThread) {
        return `/talk/content/${talkThread}/${citation}`;
    }
    if (usecaseName) {
        const parts = citation.split("-");

        // Check if the last part contains digits
        const citationWithoutPageNumber =
            parts.length > 1 ? [...parts].slice(0, parts.length - 1).join("-") + "." + parts[parts.length - 1]?.split(".")[1] : parts[parts.length - 1];

        return `/uc/content/${usecaseName}/${citationWithoutPageNumber}`;
    } else return `/content/${citation}`;
}

export function extractDomainName(url: string): string {
    // Remove the protocol (e.g., https://) if present
    const urlWithoutProtocol = url.split("://")?.[1] || url;

    // Split the URL by "/", take the first part, and then split by "." to get the domain name
    const parts = urlWithoutProtocol.split("/");
    const domainNameParts = parts[0].split(".");

    // The last two parts of the domain name are usually the top-level and second-level domain
    // If there are more than two parts, consider the last two
    const domainName = domainNameParts.slice(-2).join(".");

    return domainName;
}

/**
 * Sends a POST request to the "/reactToMessage" endpoint with the provided data and returns a Promise that resolves to any type.
 * If the response status is 403, the user is redirected to the "/login" page.
 * If the response status is greater than 299 or not OK, an error is thrown.
 * If an error occurs during the fetch request, it is logged to the console and a default object is returned.
 *
 * @param {ReactToMessageRequest} data - The data to be sent in the body of the POST request.
 * @returns {Promise<any>} - The parsed response from the fetch request, or a default object if an error occurs.
 * @throws {Error} - If the response status is greater than 299 or not OK.
 *
 * @async
 * @function ReactToMessage
 * @export
 */

export async function ReactToMessage(data: ReactToMessageRequest): Promise<any> {
    const input_url = "/reactToMessage";

    try {
        const response = await fetch(input_url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        });

        const parsedResponse: any = await response.json();
        if (response.status === 403) {
            window.location.replace(`${location.origin}/#/login`);
        }
        if (response.status > 299 || !response.ok) {
            throw Error(parsedResponse.error || "Unknown error");
        }
        return parsedResponse;
    } catch (error) {
        console.error("api.ts =====>", { error });
        return { rows: [], error: "error" };
    }
}

/**
 * Returns the chat name based on the provided pathname and chat session ID.
 * The chat name is determined by a switch statement that checks if the pathname starts with certain strings.
 * If none of the cases match, the default chat name "Chat.pdf" is returned.
 *
 * @param {string} pathname - The pathname to check.
 * @param {string | undefined} chat_session_id - The chat session ID to include in the chat name.
 * @returns {string} - The chat name.
 *
 * @function getChatName
 * @export
 */
export function getChatName(pathname: string, chat_session_id: string | undefined) {
    switch (true) {
        case pathname === "/":
            return `Chat_SoothGPT_Agent_${chat_session_id}.pdf`;
        case pathname.startsWith("/qa"):
            return `Chat_Ask_A_Question_${chat_session_id}.pdf`;
        case pathname.startsWith("/pureplus"):
            return `Chat_ChatGPT_4.0_${chat_session_id}.pdf`;
        case pathname.startsWith("/pure"):
            return `Chat_ChatGPT_${chat_session_id}.pdf`;
        case pathname.startsWith("/chat"):
            return `Chat_Ask_A_Question_${chat_session_id}.pdf`;
        case pathname.startsWith("/dalle3"):
            return `Chat_Dall-E_3_${chat_session_id}.pdf`;
        case pathname.startsWith("/business_user"):
            return `Chat_Business Users_${chat_session_id}.pdf`;
        case pathname.startsWith("/usecase/legal"):
            return `Chat_Ask Legal_${chat_session_id}.pdf`;
        case pathname.startsWith("/usecase/legal"):
            return `Chat_Business Users_${chat_session_id}.pdf`;
        case pathname.startsWith("/usecase"):
            return `Chat_UseCase_${chat_session_id}.pdf`;
        case pathname.startsWith("/talk"):
            return `Chat_DocuSpeak_${chat_session_id}.pdf`;
        case pathname.startsWith("/ask"):
            return `Chat_Ask_A_Question_${chat_session_id}.pdf`;
        case pathname.startsWith("/bing"):
            return `Chat_Project_Genie_${chat_session_id}.pdf`;
        default:
            return "Chat.pdf";
    }
}

export function getPageNumberFromFilename(filename: string) {
    // Split the filename by hyphens (-)
    const parts = filename.split("-");

    // Check if the last part contains digits only
    let potentialPageNumber = parts.length > 1 ? parts[parts.length - 1] : "null";
    if (potentialPageNumber && potentialPageNumber.length > 0) {
        potentialPageNumber = potentialPageNumber.split(".")[0];
    } else {
        potentialPageNumber = "null";
    }

    if (Number.isNaN(potentialPageNumber)) {
        return null; // Return null if not a valid page number
    } else {
        return Number.parseInt(potentialPageNumber, 10); // Parse the string as integer
    }
}

export const stringifyReqBody = (options: any) => {
    return JSON.stringify({
        question: options?.question || options?.current_prompt || "",
        history: options.history,
        approach: options.approach,
        session_id: options.session_id,
        current_question: options.current_prompt || options?.question,
        session_document_key: options.session_document_key,
        filePath: options?.filePath || options?.filepath || "",
        content: options.content,
        isNewChatThread: options.isNewChatThread,
        fewShotData: options.fewShotExample,
        conversationStyle: options.conversationStyle,
        threadUrl: options.threadUrl,
        cogIndex: "question-on-doc",
        menu_name: options?.menu_name || "",
        name: options?.name || "",
        session_filepath_document_key: options?.session_filepath_document_key || "",
        is_ImageUploaded: options?.is_ImageUploaded || false,
        talk: {
            oaModel: options.talk?.oaModel,
            cogIndex: options.talk?.cogIndex,
            storage: options.talk?.storage,
            fileName: options.talk?.fileName,
            conversationStyle: options.talk?.conversationStyle
        },
        overrides: {
            semantic_ranker: options.overrides?.semanticRanker || false,
            semantic_captions: options.overrides?.semanticCaptions || false,
            top: options.overrides?.top || "",
            temperature: options.overrides?.temperature || "",
            prompt_template: options.overrides?.promptTemplate || "",
            prompt_template_prefix: options.overrides?.promptTemplatePrefix || "",
            prompt_template_suffix: options.overrides?.promptTemplateSuffix || "",
            exclude_category: options.overrides?.excludeCategory || "",
            suggest_followup_questions: options.overrides?.suggestFollowupQuestions || false,
            presence_penalty: options.overrides?.presence_penalty || "",
            frequency_penalty: options.overrides?.frequency_penalty || "",
            top_p: options.overrides?.top_p || "",
            max_tokens: options.overrides?.max_token || "",
            stop: options.overrides?.stop || "",
            deploymentName: options.overrides?.deploymentName || "",
            pastMessagesToInclude: options.overrides?.pastMessagesToInclude || ""
        }
    });
};

export async function UserHistoryApi(chatMode: string): Promise<ChatThread[]> {
    const input_url = `/get_chat_threads/${chatMode}`;
    try {
        const response = await fetch(input_url, {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        });
        if (response.status === 403) {
            const isUserLoggedIn: boolean = Cookies.get("isUserLoggedIn") == "False" || Cookies.get("isUserLoggedIn") == undefined;
            if (isUserLoggedIn) {
                window.location.replace(`${location.origin}/#/login`);
            } else {
                window.location.replace(`${location.origin}/#/login`);
            }
        }
        const parsedResponse: ChatThread[] = await response.json();

        if (response.status > 299 || !response.ok) {
            throw Error(`Unknown error with status ${response.status}`);
        }
        return parsedResponse;
    } catch (error) {
        console.error("api.ts =====>", { error });
        return [];
    }
}

export async function processApiResponse(apiResponse: any) {
    if (apiResponse.status === 403) {
        const isUserLoggedIn: boolean = Cookies.get("isUserLoggedIn") == "False" || Cookies.get("isUserLoggedIn") == undefined;
        if (isUserLoggedIn) {
            window.location.replace(`${location.origin}/#/login`);
        } else {
            window.location.replace(`${location.origin}/login`);
        }
    }
    const parsedResponse: AskResponse = await apiResponse.json();
    if (apiResponse.status > 299 || !apiResponse.ok) {
        throw Error(parsedResponse.error || "Unknown error");
    }

    return parsedResponse;
}

export const handleRemoveSession = async (chatSessionId: string, sessionKeyName: string, isAnswer: boolean) => {
    if (chatSessionId?.trim() && isAnswer) {
        const url = `/remove_instance`;
        try {
            const response = await fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({ chatSessionId, session_id: chatSessionId }),
                credentials: "include"
            });
            const data = await response.json();
            if (data) {
                sessionStorage.removeItem(sessionKeyName);
            }
        } catch (error) {
            console.error("error===>", error);
        }
    }
};
