import { DropdownMenuItemType, type IDropdownOption } from "@fluentui/react";
import Cookies from "js-cookie";
import type React from "react";
import { useEffect, useMemo, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Margin, usePDF } from "react-to-pdf";
import { MenuContext } from "./MenuContext";
import { type ChatThread, type MenuItemType, UserMenuApi, getChatName } from "./api";
import { localMenus } from "./assets/data/examplesData";
import { checkUserLoggedIn, fetchWithRetry, getCurrentMenu } from "./util";
type Props = {
    children: React.ReactNode;
};

const defaultMenuPathMappingObject = {
    "/": "SecureGPT",
    "/404": "Not Found",
    "/500": "Server Error",
    "/about": "About",
    "/help": "Help",
    "/login": "login",
    "/logout": "logout",
    "/pureplus": "SecureGPT",
    "/purepluso": "SecureGPT",
    "/unauthorized": "Unauthorized"
};

// MenuProvider is a context provider that provides the selected menu item, a function to set the selected menu item, a function to generate a PDF, a reference to the target element, and a flag to indicate whether the answers are being displayed.
export function SoothGptProvider({ children }: Props) {
    const { pathname } = useLocation();
    const { chat_session_id, name } = useParams();
    const splitPath = pathname.split("/");
    const usecaseName = name || pathname.startsWith("/usecase") ? pathname.split("/")[2] : pathname.split("/")[1];
    let modifiedPath = pathname.startsWith("/usecase") ? `/${splitPath[1]}/${splitPath[2]}` : `/${splitPath[1]}`;
    const initialMenuPathMappingObject = sessionStorage.getItem("sessionMenuPathMapping") || JSON.stringify(defaultMenuPathMappingObject);
    const parsedInitialMenuPathMappingObject = JSON.parse(initialMenuPathMappingObject);
    const initialMenus = sessionStorage.getItem("menuItems") || JSON.stringify([]);
    const parsedInitialMenus = JSON.parse(initialMenus);
    const [selectedMenuItem, setSelectedMenuItem] = useState<string>("SecureGPT");
    const [isContinuing, setIsContinuing] = useState<boolean>(false);
    const [isAnswers, setIsAnswers] = useState<boolean>(false);
    const [menuItems, setMenuItems] = useState<MenuItemType[]>(parsedInitialMenus);
    const [formattedUserMenus, setFormattedUserMenus] = useState<IDropdownOption<any>[]>([]);
    const [isMenuLoading, setIsMenuLoading] = useState<boolean>(false);
    const [userMenus, setUserMenus] = useState<MenuItemType[]>([]);
    const [userThreads, setUserThreads] = useState<ChatThread[]>([]);
    const [user, setUser] = useState<string>("");
    const [menuPathMappingObject, setMenuPathMappingObject] = useState<Object>(parsedInitialMenuPathMappingObject);
    const [authorizedPath, setAuthorizedPath] = useState<string>(parsedInitialMenuPathMappingObject[modifiedPath] || "");

    const { toPDF, targetRef } = usePDF({
        method: "save",
        filename: getChatName(pathname, chat_session_id || ""),
        page: { margin: Margin.MEDIUM }
    });
    const isLocalhost = window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1";
    let currentMenu = getCurrentMenu(pathname, usecaseName);

    if (Cookies.get("isUserLoggedIn") === undefined) {
        Cookies.set("isUserLoggedIn", "False");
    }

    const makeApiRequest = async () => {
        try {
            // If the application is not running on localhost and the user menus are empty, set isMenuLoading to true
            if (!isLocalhost && menuItems.length === 0) {
                setIsMenuLoading(true);
            }
            const result = await UserMenuApi();
            if (result.error) {
                return;
            }
            if (!isLocalhost && menuItems.length === 0) {
                Cookies.set("isUserLoggedIn", "True");
                setMenuItems(result.menus);
                sessionStorage.setItem("menuItems", JSON.stringify(result.menus));
            }
            if (menuItems.length === 0) makeMenuPathMappingObject(result.menus);

            const formattedMenu = result.menus.map(menuItem => {
                const { MenuItemID, MenuItemName, Path } = menuItem;
                const isHeader = Path === "#";

                const t: IDropdownOption = {
                    key: MenuItemID.toString(),
                    text: MenuItemName,
                    ...(isHeader ? { itemType: DropdownMenuItemType.Header } : { data: Path })
                };

                return t;
            });

            //Set formattedUserMenus to new formattedMenu
            setFormattedUserMenus(formattedMenu);
        } catch (e) {
            console.error(e);
        } finally {
            // Set the user threads to the result's threads in reverse order
            setIsMenuLoading(false);
        }
    };

    const makeHistoryApiCall = async (chatMode: string) => {
        const options = {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        };

        const retries = 2;
        let attempt = 0;
        let success = false;
        let result;

        while (attempt <= retries && !success) {
            try {
                result = await fetchWithRetry(`/get_chat_threads/${chatMode}`, options);
                if (result.length > 0) {
                    // Set the user threads to the result's threads in reverse order
                    setUserThreads(result.reverse());
                    success = true;
                } else {
                    attempt += 1;
                }
            } catch (e) {
                console.error(`Attempt ${attempt + 1} failed:`, e);
                attempt += 1;
                if (attempt > retries) {
                    console.error("Error fetching chat threads after multiple attempts");
                }
            }
        }
    };

    const makeMenuPathMappingObject = useMemo(
        () => (menuItems: MenuItemType[]) => {
            const menuPathMapping: { [key: string]: string } = {};
            const filteredMenuItems = menuItems.filter(menu => menu.Path !== "#" && menu.IsActive);
            ["/", "/purepluso", "/pureplus", "/login", "/help", "/about", "/logout", "/unauthorized", "/404", "/500"].forEach(path => {
                // @ts-ignore
                menuPathMapping[path] = defaultMenuPathMappingObject[path];
            });
            filteredMenuItems.forEach((menu: MenuItemType) => {
                const menuPath = menu.Path.startsWith("/") ? menu.Path : `/${menu.Path}`;
                menuPathMapping[menuPath] = menu.MenuItemName;
            });

            setMenuPathMappingObject(menuPathMapping);
            setAuthorizedPath(menuPathMapping[modifiedPath] || "");
            sessionStorage.setItem("sessionMenuPathMapping", JSON.stringify(menuPathMapping));
            return menuPathMapping;
        },
        [menuItems]
    );

    const uniqueRoleIDsArray: string | number[] = [];

    useEffect(() => {
        if (isLocalhost) {
            setMenuItems(localMenus);
            sessionStorage.setItem("menuItems", JSON.stringify(localMenus));
            makeMenuPathMappingObject(localMenus);
        }
        if (!isLocalhost && menuItems.length === 0) {
            makeApiRequest();
        }
        setAuthorizedPath(parsedInitialMenuPathMappingObject[modifiedPath] || "");

        checkUserLoggedIn();
        // Cleanup function
        return () => {
            window.removeEventListener("load", checkUserLoggedIn);
        };
    }, []);

    // useEffect hook that runs when 'pathname' or 'chat_session_id' changes
    useEffect(() => {
        // Check if the user is logged in by reading a cookie
        const isUserLoggedIn = Cookies.get("isUserLoggedIn");

        // If menuItems is not defined or empty, or the user is not logged in, exit the useEffect
        if (isUserLoggedIn !== "True") {
            return;
        }

        if (isLocalhost) makeMenuPathMappingObject(menuItems);

        const splitPath = pathname.split("/");
        modifiedPath = pathname.startsWith("/usecase") ? `/${splitPath[1]}/${splitPath[2]}` : `/${splitPath[1]}`;
        // @ts-ignore
        const menuNameFromMenuPathMapping = menuPathMappingObject[modifiedPath];
        setAuthorizedPath(menuNameFromMenuPathMapping || "");
        setSelectedMenuItem(menuNameFromMenuPathMapping || "SecureGPT");
        currentMenu = getCurrentMenu(pathname, usecaseName);

        if (currentMenu) {
            makeHistoryApiCall(currentMenu);
        }
    }, [pathname]);

    useEffect(() => {
        if (menuItems.length > 0) makeMenuPathMappingObject(menuItems);
    }, [menuItems]);

    const routeForPdfButton: string[] = ["/chat", "/bing", "/pure", "/usecase", "/qa", "/ask", "/talk", "/braziltaxcode", "/dalle"];

    // Check if the current pathname matches any of the route patterns
    const isPdfButtonVisible = routeForPdfButton.some(pattern => pathname.startsWith(pattern)) || location.pathname === "/";
    const value = {
        selectedMenuItem,
        setSelectedMenuItem,
        toPDF,
        targetRef,
        isAnswers,
        setIsAnswers,
        isContinuing,
        setIsContinuing,
        menuItems,
        setMenuItems,
        user,
        setUser,
        formattedUserMenus,
        isMenuLoading,
        isPdfButtonVisible,
        userMenus,
        userThreads,
        currentMenu,
        authorizedPath
    };

    return <MenuContext.Provider value={value}>{children}</MenuContext.Provider>;
}
