// @ts-nocheck
import documentIcon from "../assets/questionIcon1.png";
import textIcon from "../assets/questionIcon2.png";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { stringifyReqBody } from "../api";
import { EXTERNAL_LINK_REGEX, FILE_EXTENSIONS, USER_INACTIVE_TIMEOUT } from "../constants";

/**
 * Get an array of unique property values from an array of objects.
 * @param data The array of objects to search.
 * @param key The property name to search for unique values.
 * @returns An array of unique property values of type K.
 */
export const getUniquePropertyValues = <K extends keyof any>(data: Record<K, any>[], key: K): K[] => {
    // Use a Set to store unique property values
    const uniqueValues = new Set<K>(); // Define the Set with the property type

    // Iterate over the data array and add the property value to the Set
    data.forEach(item => {
        uniqueValues.add(item[key]);
    });

    // Convert the Set back to an Array if you need to work with an array
    return Array.from(uniqueValues) || [];
};

export async function makeStreamingApiRequest(api, request, setIsLoading, setAnswers, setStreamEnd, setError, lastQuestion) {
    try {
        const response = await fetch(api, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Accept: "text/event-stream"
            },
            body: stringifyReqBody(request)
        });

        if (!response.ok) {
            setError(`Fail to fetch response`);
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        checkResStatus(response, setError);

        const reader = response.body.getReader();
        const decoder = new TextDecoder();
        let rawData = "";

        while (true) {
            const { value, done } = await reader.read();
            if (done) {
                setStreamEnd(true);
                break;
            }
            rawData += decoder.decode(value, { stream: true });
            rawData = processStreamData(rawData, setIsLoading, setAnswers, lastQuestion);
        }
    } catch (error) {
        setError(`Fail to fetch response`);
        console.error("Fetch error:", error.message);
    }
}

function processStreamData(rawData, setIsLoading, setAnswers, lastQuestion) {
    const lines = rawData.split("\n");
    for (let i = 0; i < lines.length - 1; i++) {
        const line = lines[i].trim();
        if (line) {
            setIsLoading(false);
            try {
                const parsedData = JSON.parse(line);
                processParsedData(parsedData, setAnswers, lastQuestion);
            } catch (error) {
                console.error("Error parsing JSON:", error);
            }
        }
    }
    // Return any incomplete data to be processed with the next chunk
    return lines[lines.length - 1];
}

function processParsedData(parsedData, setAnswers, lastQuestion) {
    setAnswers(prevAnswers => {
        // Check if the last entry in the previous answers array has a messageID or id.
        const hasId = entry => entry?.messageID || entry?.id;
        const lastAnswer = prevAnswers.length > 0 ? prevAnswers[prevAnswers.length - 1] : null;

        // If the last answer has an ID, we append the new data; otherwise, we replace the last entry.
        if (lastAnswer && hasId(lastAnswer[1])) {
            return [...prevAnswers, [lastQuestion, parsedData]];
        } else if (prevAnswers.length > 0) {
            return [...prevAnswers.slice(0, -1), [lastQuestion, parsedData]];
        } else {
            return [[lastQuestion, parsedData]];
        }
    });
}

export function checkResStatus(res, setError) {
    // const navigate = useNavigate();
    if (res.ok && res.status === 200) {
        return;
    } else if (res.status === 403) {
        window.location.replace(`${location.origin}/#/login`);
        setError("Please login to continue");
        throw new Error("Unauthorized");
    } else if (res.status >= 400 && res.status < 500 && res.status !== 429) {
        setError("fail to fetch response");
        throw new Error("Client side error");
    } else {
        setError("Server error");
        throw new Error("Server error");
    }
}

export function makeUseCaseShortQuestions(paramData) {
    const { FAQ_1 = "", FAQ_2 = "", FAQ_3 = "", FAQ_4 = "", FAQ_5 = "", FAQ_6 = "", IconImages = {} } = paramData;
    const prepareFormattedSamplePrompt = [FAQ_1, FAQ_2, FAQ_3, FAQ_4, FAQ_5, FAQ_6]
        .filter(field => field && field.trim() !== "")
        .map((field, i) => ({
            text: field,
            value: field,
            image: IconImages[i], //i % 2 === 0 ? documentIcon : textIcon,
            type: "chat"
        }));

    return prepareFormattedSamplePrompt;
}

export function splitIntoChunks(string, chunkSize = 5) {
    // Split the string into words
    const words = string.split(" ");

    // Create the chunks
    const chunks = [];
    for (let i = 0; i < words.length; i += chunkSize) {
        chunks.push(words.slice(i, i + chunkSize).join(" "));
    }

    return chunks;
}

const menuMapping = {
    "/soothsayer-concierge": "Soothsayer Concierge",
    "/purepluso": "GPT 4o",
    "/pureplus": "Pure Plus",
    "/pure": "Pure",
    "/chat": "SoothGPT Chat",
    "/business_user": "Business User",
    "/usecase/legal": "Usecase legal",
    "/usecase/hr": "Usecase hr",
    "/usecase/pp": "Usecase pp",
    "/usecase/mm": "Usecase mm",
    "/usecase/oo": "Usecase oo",
    "/talk": "Talk",
    "/qa": "SoothGPT Ask",
    "/ask": "SoothGPT Ask",
    "/bing": "Bing",
    "/ground_truth": "Ground Truth",
    "/evaluation": "Evaluation",
    "/result": "Results",
    "/evaluation_feedback": "Feedback"
};

export const getCurrentMenu = (pathname, useCaseName) => {
    const isUsecase = pathname.startsWith("/usecase/") || pathname.startsWith("usecase/");
    const generalizedPath = isUsecase ? (pathname.startsWith("/usecase") ? pathname.slice(1) : pathname) : pathname;
    useCaseName = generalizedPath.split("/")[1];
    if (isUsecase) {
        return "Usecase " + useCaseName;
    }
    for (const key in menuMapping) {
        if (pathname.startsWith(key)) {
            return menuMapping[key];
        }
    }
    return "";
};

let timer = null;

/**
 * Starts the inactivity timer.
 */
export const startInactivityTimer = () => {
    timer = window.setTimeout(() => {
        navigateToLogin();
    }, USER_INACTIVE_TIMEOUT);
};

/**
 * Clears the inactivity timer.
 */
export const clearInactivityTimer = () => {
    if (timer) {
        window.clearTimeout(timer);
    }
};

/**
 * Resets the inactivity timer.
 */
export const resetInactivityTimer = () => {
    clearInactivityTimer();
    startInactivityTimer();
};

/**
 * Navigates to the login page.
 */
export const navigateToLogin = () => {
    sessionStorage.clear();
    Cookies.get("isUserLoggedIn") == "True" ? window.location.replace(`${location.origin}/logout`) : window.location.replace(`${location.origin}/#/login`);
};

export const checkUserLoggedIn = () => {
    const isUserLoggedIn = Cookies.get("isUserLoggedIn");
    if (isUserLoggedIn == "False" || isUserLoggedIn == undefined) {
        window.location.replace(`${location.origin}/#/login`);
    } else {
        window.location.replace(window.location.hash);
    }
};

export const handleRemoveCosmosReference = async options => {
    const url = `/remove_document`;
    try {
        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(options),
            credentials: "include"
        });
        const data = await response.json();
    } catch (error) {
        console.error("error===>", error);
    }
};

export const fetchWithRetry = async (url, options = {}, retries = 2) => {
    for (let i = 0; i < retries; i++) {
        try {
            const response = await fetch(url, options);
            if (!response.ok) {
                if (response.status === 403) {
                    const isUserLoggedIn: boolean = Cookies.get("isUserLoggedIn") == "False" || Cookies.get("isUserLoggedIn") == undefined;
                    if (isUserLoggedIn) {
                        window.location.replace(`${location.origin}/#/login`);
                    } else {
                        window.location.replace(`${location.origin}/#/login`);
                    }
                    return;
                } else {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
            }
            return await response.json();
        } catch (error) {
            if (i === retries - 1) {
                throw error; // If it's the last retry, throw the error
            }
            console.warn(`Retrying... (${i + 1}/${retries})`);
        }
    }
};

export const extractFormulas = text => {
    // Regex to capture both block and inline LaTeX formulas
    const formulaPattern = /\\\[(.*?)\\\]|\\\((.*?)\\\)/g;
    const matches = [];
    let match;
    while ((match = formulaPattern.exec(text)) !== null) {
        if (match[1]) {
            matches.push(match[1]); // Block formula
        } else if (match[2]) {
            matches.push(match[2]); // Inline formula
        }
    }
    return matches;
};

export const isExternalLink = (string: string) => {
    const isMatch = !!string.match(EXTERNAL_LINK_REGEX);
    return isMatch;
};

export const makeValidUrl = (url: string): string => {
    // Regex to check if URL starts with http, https, or www, or contains a file extension
    const urlPattern = new RegExp(`^(https?:\/\/|www\.)|\.(?:${FILE_EXTENSIONS})(?=[?#]|$)`, "i");

    // If the URL doesn't match the pattern, prepend 'https://'
    if (!urlPattern.test(url)) {
        return `https://${url}`;
    }

    // Return the original URL if it's already valid
    return url;
};
