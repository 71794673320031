// @ts-nocheck
// fetchInterceptor.ts
import { resetInactivityTimer } from ".";

// Save the original fetch function
const originalFetch = window.fetch;

// Override the fetch function
window.fetch = async (...args: [RequestInfo, RequestInit?]): Promise<any> => {
    resetInactivityTimer();
    // Optionally, you can add more custom logic here, like tracking or modifying requests

    // Call the original fetch function
    const response = await originalFetch(...args);

    // Optionally, you can add more custom logic here, like tracking or modifying responses

    return response;
};
