import type { IChoiceGroupOption } from "@fluentui/react";

export const CHAT_APPROACHES = {
    PureReadRetrieveRead: "prrr",
    PureOReadRetrieveRead: "orrr",
    VisionReadRetriveRead: "vrrr",
    RetrieveThenRead: "rtr",
    ReadRetrieveRead: "rrr",
    LegalReadRetrieveRead: "lrrr",
    useCaseReadRetrieveRead: "crrr",
    ReadRetrieveReadNew: "rrr_new",
    ReadDecomposeAsk: "rda",
    dalleReadRetrieveRead: "drrr",
    theNewApproach: "new"
};

// Array of route patterns to check against
export const ROUTE_FOR_PDF_BUTTON: string[] = ["/chat", "/bing", "/purepluso", "/pureplus", "/pure", "/usecase", "/qa", "/ask", "/talk"];
export const ROUTE_FOR_ADVANCE_SETTING_PANEL: string[] = ["/chat", "/bing", "/usecase", "/qa", "/ask", "/talk"];

export const ACCEPTED_FILE_UPLOAD_FORMATE_FOR_TALK = [
    ".csv",
    ".docx",
    ".pdf",
    ".pptx",
    ".txt",
    ".xlsx",
    ".c",
    ".cpp",
    ".html",
    ".java",
    ".json",
    ".md",
    ".php",
    ".py",
    ".rb",
    ".tex",
    ".css",
    ".js",
    ".ts",
    ".xml"
];

// Path & chat mode used for filtering constants
export const ChatPath = "chat";
export const ChatApiPath = "chat";
export const ChatMode = "SoothGPT Chat";
export const bingPath = "bing";
export const bingApiPath = "bing";
export const bingChatMode = "Bing";
export const purePath = "pure";
export const pureApiPath = "pure";
export const pureChatMode = "Pure";
export const purePlusPath = "pureplus";
export const purePlusApiPath = "pureplus";
export const purePlusChatMode = "Pure Plus";
export const purePlusOPath = "purepluso";
export const purePlusOApiPath = "purepluso";
export const purePlusOChatMode = "GPT 4o";
export const useCaseInitialPath = "usecase";
export const useCaseInitialApiPath = "usecase";
export const useCaseInitialChatMode = "Usecase ";
export const askAQuestionPath = "qa";
export const askAQuestionApiPath = "qa";
export const askAQuestionChatMode = "SoothGPT Ask";
export const askPath = "ask";
export const askApiPath = "ask";
export const askChatMode = "Ask";
export const talkToDocPath = "talk";
export const talkToDocApiPath = "talk";
export const talkToDocChatMode = "Talk";
export const brazilTaxCodePath = "braziltaxcode";
export const brazilTaxCodeApiPath = "braziltaxcode";
export const dallEPath = "dalle";
export const dallEApiPath = "dalle";
export const dallE3Path = "dalle3";
export const dallE3ApiPath = "dalle3";
export const ragasGroundTruthPath = "ground_truth";
export const ragasGroundTruthApiPath = "ground_truth";
export const ragasEvaluationPath = "evaluation";
export const ragasEvaluationApiPath = "evaluation";
export const ragasResultPath = "result";
export const ragasResultApiPath = "result";
export const ragasEvaluationFeedbackPath = "feedback";
export const ragasEvaluationFeedbackApiPath = "feedback";
export const businessUserPath = "business_user";
export const businessUserApiPath = "business_user";
export const aboutPath = "about";
export const aboutApiPath = "about";
export const helpPath = "help";
export const helpApiPath = "help";
export const loginPath = "login";
export const loginApiPath = "login";
export const logoutPath = "logout";
export const logoutApiPath = "logout";
export const removeInstanceApiPath = "remove_instance";
export const processDocsApiPath = "process_docs";
// export const logoutApiPath = "logout";

export const useCaseNameParam = "name";
export const chatSessionIdParam = "chat_session_id";
export const ragasIdParam = "ragId";

// Advance setting Panel constants
export const HEADER_TEXT = "Configure answer generation";
export const PROMPT_TEMPLATE_LABEL = "Override prompt template";
export const PROMPT_ARIA_LABEL = "Add instructions for the response generation. This will work as System Prompt for LLM Agents";
export const EXCLUDE_CATEGORY_LABEL = "Exclude category";
export const EXCLUDE_CATEGORY_ARIA_LABEL = "Category texts to be excluded from the generated response.";
export const RETRIEVE_DOCUMENT_BUTTON_LABEL = "Override prompt template";
export const RETRIEVE_DOCUMENT_BUTTON_TOOLTIP_CONTENT = "Number of documents to refer for generating response.";
export const RETRIEVE_DOCUMENT_INCREMENT_BUTTON_ARIA_LABEL = "Increase value by 1";
export const RETRIEVE_DOCUMENT_DECREMENT_BUTTON_ARIA_LABEL = "Decrease value by 1";
export const MAX_TOKEN_BUTTON_LABEL = "Max Tokens: ";
export const MAX_TOKEN_BUTTON_TOOLTIP_CONTENT = "Limits how many tokens or words will be generated in the output text completion.";
export const USE_SEMANTIC_RANKER_CHECKBOX_LABEL = "Use semantic ranker for retrieval";
export const USE_SEMANTIC_CAPTIONS_CHECKBOX_LABEL = "Use query-contextual summaries instead of whole documents";
export const USE_SUGGEST_FOLLOWUP_QUESTIONS_CHECKBOX_LABEL = "Suggest follow-up questions";
export const CUSTOM_SLIDER_TEMPERATURE_LABEL = "Temperature";
export const CUSTOM_SLIDER_TEMPERATURE_TOOLTIP_CONTENT =
    "Controls creativity vs accuracy, with higher values resulting in more surprising but potentially nonsensical text.";
export const PROMPT_TEMPLATE_PREFIX_LABEL = "Override prompt prefix template";
export const PROMPT_TEMPLATE_SUFFIX_LABEL = "Override prompt suffix template";
export const ADDITION_ICON_NAME = "CalculatorAddition";
export const SUBTRACT_ICON_NAME = "CalculatorSubtract";

export const QUESTION_INPUT_PLACE_HOLDER_LABEL = "Type a new question (e.g. How do I find information on Sooth Products or Services? )";
export const ONE_SHOT_QUESTION_INPUT_PLACEHOLDER = "Example: What can you tell me about the Factory of the Future?";
export const DALLE_QUESTION_INPUT_PLACEHOLDER = "An Impressionist oil painting of sunflowers in a purple vase…";

export const DALLE_DOWNLOAD_FILE_NAME = "Sooth_AI_Image.png";

// Choose a conversation style
export const WRAPPER_TITLE = "Choose a conversation style";
export const CONVERSATION_STYLE = [
    { btnLabel: "Creative", btnSubLabel: "More" },
    { btnLabel: "Balanced", btnSubLabel: "More" },
    { btnLabel: "Precise", btnSubLabel: "More" }
];
/**
 * Function to handle the change event of the approach choice group.
 */
export const APPROACHES: IChoiceGroupOption[] = [
    {
        key: CHAT_APPROACHES.RetrieveThenRead,
        text: "Retrieve-Then-Read"
    },
    {
        key: CHAT_APPROACHES.ReadRetrieveRead,
        text: "Read-Retrieve-Read"
    },
    {
        key: CHAT_APPROACHES.ReadDecomposeAsk,
        text: "Read-Decompose-Ask"
    }
];

// response api messages
export const ON_THUMB_CLICK_SUCCESS_MESSAGE = "Document updated successfully";
export const API_SERVER_ERROR_MESSAGE = "Could not generate response. Please try again.";
export const API_UNAUTHORIZED_ERROR_MESSAGE = "Unauthorized";
export const API_LOGIN_ERROR_MESSAGE = "Please login to continue.";
export const API_CLIENT_SIDE_ERROR_MESSAGE = " Please try again.";

export const CUSTOM_INCREMENT_DECREMENT_BUTTON_STYLES = {
    color: "#000",
    fontSize: "10px"
};

export type ExampleModel = {
    text: string;
    value: string;
    type?: string;
    tip?: string;
    image?: string;
};

export const MAX_TOKENS = 128000;
export const GPT4_TURBO_MAX_TOKENS = 80000;
export const DALLE3_MAX_TOKENS = 4000;
export const USER_INACTIVE_TIMEOUT = 1000 * 60 * 30;

export const DOMAIN_NAMES = [
    ".com",
    ".org",
    ".net",
    ".edu",
    ".gov",
    ".mil",
    ".in",
    ".us",
    ".co",
    ".uk",
    ".ca",
    ".de",
    ".fr",
    ".au",
    ".jp",
    ".ru",
    ".cn",
    ".br",
    ".it",
    ".es",
    ".mx",
    ".kr",
    ".nl",
    ".se",
    ".ch",
    ".be",
    ".ai",
    ".io",
    ".tv"
];

// Regex to check if URL starts with http, https, or www, or contains a file extension
export const EXTERNAL_LINK_REGEX =
    /\b(?:https?:\/\/|www\.)\S+\.(?:[a-zA-Z]{2,})(?<!\.(?:pdf|pptx?|txt|docx?|xml|csv|json|zip|rar|tar\.gz|7z))\b|\b(?:[a-zA-Z0-9.-]+\.(?:[a-zA-Z]{2,}))(?<!\.(?:pdf|pptx?|txt|docx?|xml|csv|json|zip|rar|tar\.gz|7z))\b/g;

// List of common file extensions
export const FILE_EXTENSIONS =
    "pdf|docx?|xlsx?|pptx?|txt|rtf|csv|tsv|json|xml|css|jsx?|tsx?|jpg|jpeg|png|gif|bmp|svg|mp3|mp4|wav|avi|mov|wmv|flv|zip|rar|7z|tar|gz";

export const VISION_FILE_MAX_SIZE = 1.4 * 1024 * 1024; // conversion in base64 increase size by 33%

export const CONVERSATION_STYLE_DATA = [
    { btnLabel: "Creative", btnSubLabel: "More" },
    { btnLabel: "Balanced", btnSubLabel: "More" },
    { btnLabel: "Precise", btnSubLabel: "More" }
];
